import { render, staticRenderFns } from "./Loader.vue?vue&type=template&id=0eaccdca&scoped=true&"
import script from "./Loader.vue?vue&type=script&lang=js&"
export * from "./Loader.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eaccdca",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vimal/sites/laravel/laravel-reviews/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0eaccdca')) {
      api.createRecord('0eaccdca', component.options)
    } else {
      api.reload('0eaccdca', component.options)
    }
    module.hot.accept("./Loader.vue?vue&type=template&id=0eaccdca&scoped=true&", function () {
      api.rerender('0eaccdca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/front/js/components/Common/Loader.vue"
export default component.exports